import printWindow from './printWindow';

const printInFrame = async (iframe: HTMLIFrameElement, url: string): Promise<void> =>
  new Promise((resolve, reject) => {
    /* eslint-disable no-param-reassign */
    // disabling eslint due to the need to access the iframe external to this function
    let printError: Error | null = null;

    const oldFrame = document.getElementById('printFrame');
    if (oldFrame && oldFrame.parentNode) {
      oldFrame.parentNode.removeChild(oldFrame);
    }

    iframe.id = 'printFrame';
    iframe.name = 'print';
    iframe.style.display = 'none';
    iframe.removeAttribute('sandbox');
    document.body.appendChild(iframe);

    const finishedPrinting = () => {
      if (printError) {
        reject(printError);
      } else {
        resolve();
      }
    };

    iframe.onload = () => {
      try {
        printWindow(iframe.contentWindow);
      } catch (error) {
        printError = error as Error;
      } finally {
        setTimeout(finishedPrinting, 2000);
      }
    };

    iframe.onerror = (message) => {
      printError = new Error(`${message}`);
      finishedPrinting();
    };

    iframe.src = url;
    /* eslint-enable no-param-reassign */
  });

export default printInFrame;
