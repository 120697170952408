import React from "react";
import "./loadingOverlay.css";

type LoadingOverlayProps = {
  message: string;
};

const LoadingOverlay = ({ message }: LoadingOverlayProps) => {
  return (
    <div className="loadingOverlay">
      <div className="icon">
        <progress className="material-circular-progress" />
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default LoadingOverlay;
