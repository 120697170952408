import UAParser from 'ua-parser-js';

/**
  Autoprint is the term we use to describe opening the print dialog
  by passing it a URL (or form to submit) without needing the user
  to click the print button. Current implementation uses a hidden
  iFrame to load and then calling print on that.

  It currently only works in Chrome as in other browsers, we either
  can't target the window (SecurityError in Firefox, or print() not
  being defined on the PDF render in IE/Edge).

  Note that for our tests, we must also allow PhantomJS to be included
  in this list.
*/
const supportsAutoPrint = () => {
  const ua = UAParser();
  const safari13AndAbove =
    ua.browser.name === 'Safari' && parseInt(ua.browser.major || '0', 10) >= 13;
  return ua.browser.name === 'Chrome' || safari13AndAbove || ua.browser.name === 'PhantomJS';
};

export default supportsAutoPrint;
