import { GenieTableName, toFhirId } from '@genie-engineering/genie-fhir-converter';
import { AppName, SDK, SDKContext, SDKPrintProps } from '@genie-engineering/sdk-api';

import { GenieClient } from '../clients/genie';
import { GenieSDKOptions } from '../types';
import { doPrint } from '../utils/doPrint';
import { util } from '@genie-engineering/genie-interop-converter';
import {
  buildCareTeam,
  buildGetAddressBookData,
  buildGetCurrentProcedure,
  buildGetLocation,
  buildGetOrganization,
  buildGetPatient,
  buildGetPractitioner,
  buildListAttachmentsByPatientId,
  buildListPractitioners,
  buildSearchPractitionerAddressBook,
  getAttachmentFileById,
  getCurrentPregnancyByPatientId,
  getIsTrueFlag,
  getLabList,
} from './sdkMethods';
import { ConverterContext, HealthFund } from './type';

export const createGenieSDK = async (
  authTokens: SDKContext['auth'],
  options: GenieSDKOptions
): Promise<SDK> => {
  const genieClient = new GenieClient();
  const genieContext = await genieClient.getCurrentGenieContext();
  const { genieVersion, username: currentUsername, patientId: currentPatientId } = genieContext;
  genieClient.setGenieVersion(genieVersion);
  const constant = await genieClient.getConstant();
  const instanceId = constant.InstanceID;
  const practicePreferences = await genieClient.listPracticePreferences();
  const currentUserPreference = await genieClient.getPreference(currentUsername);
  const hoParticipants = await genieClient.listHOParticipants();
  const healthFunds: HealthFund[] = (await genieClient.listHealthFunds())
    .map((fund) => ({
      name: fund.Name,
      brandId: fund.HOP_Id_Fk
        ? hoParticipants.find(({ Id }) => Id === fund.HOP_Id_Fk)?.BrandID
        : null,
    }))
    .filter(({ name, brandId }) => !!name && !!brandId) as HealthFund[];

  const defaultPracticePreference = practicePreferences.find((pp) => pp.DefaultPractice === true);
  if (!defaultPracticePreference) {
    throw new Error('Default practice preference not found');
  }
  const currentPracticePreferenceId = defaultPracticePreference?.Id;
  const genId = defaultPracticePreference?.MedicareClaimsCustomerId;
  // # TODO remove REACT_APP_DEV_AUTH logic once all developers setup local dev genie access
  const tenantId =
    process.env.REACT_APP_DEV_AUTH === 'true'
      ? `${process.env.REACT_APP_DEV_LOCATION_ID || 'GEN15943'}-${
          process.env.REACT_APP_DEV_PRACTICE_ID || '3473CDFA0960754799BFC1DB8660C0DF'
        }`
      : `${genId}-${instanceId}`;
  const timezone = util.getTimezone();
  const allSites = await genieClient.listSites();

  const converterContext: ConverterContext = {
    timezone,
    tenantId,
    logger: console,
    correlationId: 'genieSDK',
    healthFunds,
  };

  const { $4d } = global as any;
  if (!$4d) {
    throw new Error('UnexpectedError: Not in Genie WebArea');
  }

  const onAppMounted: SDK['onAppMounted'] = async (app: AppName): Promise<SDKContext> => {
    return {
      environment: {
        platform: 'genie',
        app,
        node: 'testing',
        version: genieVersion,
      },
      view: options.view,
      auth: authTokens,
      currentOrganizationId: `${currentPracticePreferenceId || ''}`,
      currentLocationId: `${currentPracticePreferenceId || ''}`,
      currentPatientId: `${currentPatientId || ''}`,
      tenantId,
      user: {
        id: toFhirId(
          converterContext,
          GenieTableName.Preference,
          currentUserPreference.Id,
          'practitioner'
        ),
        pmsId: currentUserPreference.Username || '',
        timezone,
        name: currentUserPreference.Name || '',
        role:
          // eslint-disable-next-line no-nested-ternary
          currentUserPreference.Administrator === true
            ? 'Administrator'
            : currentUserPreference.ProviderNum
            ? 'Doctor'
            : 'Other',
      },
      options: options.contextOptions,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onAppUnmount = async (): Promise<void> => {};

  const print = async (props: SDKPrintProps): Promise<void> => {
    await doPrint(props.url);
  };

  return {
    onAppMounted,
    onAppUnmount,
    close: genieClient.close,
    getPatient: buildGetPatient(genieClient, converterContext),
    getOrganization: buildGetOrganization(genieClient, converterContext),
    getLocation: buildGetLocation(genieClient, converterContext, allSites),
    getPractitioner: buildGetPractitioner(
      genieClient,
      converterContext,
      defaultPracticePreference,
      allSites
    ),
    listPractitioners: buildListPractitioners(
      genieClient,
      converterContext,
      defaultPracticePreference,
      allSites
    ),
    getCareTeam: buildCareTeam(genieClient),
    searchPractitionerAddressBook: buildSearchPractitionerAddressBook(genieClient),
    getAddressBookData: buildGetAddressBookData(genieClient),
    getCurrentProcedure: buildGetCurrentProcedure(genieClient, converterContext),
    print,
    listAttachmentsByPatient: buildListAttachmentsByPatientId(genieClient),
    getAttachmentFile: getAttachmentFileById(genieClient),
    isFeatureActive: getIsTrueFlag(genieClient),
    getLabList: getLabList(genieClient),
    getPatientCurrentPregnancy: getCurrentPregnancyByPatientId(genieClient, converterContext),
  };
};
