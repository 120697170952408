/* eslint-disable class-methods-use-this */
import {
  GenieAddressBook,
  GenieBillingItem,
  GenieConstant,
  GenieGraphic,
  GenieHealthFund,
  GenieHOParticipant,
  GenieInterestedParty,
  GenieLaboratory,
  GenieOperation,
  GeniePatient,
  GeniePracticePreference,
  GeniePreference,
  GeniePregnancy,
  GenieRadiologist,
  GenieRecord,
  GenieSite,
  GenieTableName,
} from '@genie-engineering/genie-fhir-converter';
import { Buffer } from 'buffer';

import { ProcedureContext } from '../types';

export interface GenieContext {
  username: string;
  patientId: string;
  genieVersion: string;
}

const sanitiseQuery = (query: string | number): string | number => {
  if (typeof query === 'string') {
    return query.replace(/"/g, "'");
  }

  return query;
};

const sanitiseSqlParameter = (
  sqlParameter: Record<string, string | number>
): Record<string, string | number> => {
  const sanitisedSqlParameter: Record<string, string | number> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(sqlParameter)) {
    sanitisedSqlParameter[key] = sanitiseQuery(value);
  }
  return sanitisedSqlParameter;
};

const getSqlString = (query: string, sqlParameter: Record<string, string | number>): string => {
  let returnedQuery = query;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(sqlParameter)) {
    const re = new RegExp(`:${key}\\b`, 'g');
    returnedQuery = returnedQuery.replace(re, `"${value}"`);
  }

  return returnedQuery;
};

export class GenieClient {
  genieVersion = '';

  setGenieVersion(genieVersion?: string) {
    this.genieVersion = genieVersion || '';
  }

  fetchData = async <T extends GenieRecord>(
    tableName: GenieTableName,
    sql: string,
    sqlParameter = {},
    orderBy = '',
    limit = 0,
    offset = 0
  ): Promise<T[]> => {
    const sanitisedSqlParameter = sanitiseSqlParameter(sqlParameter);
    if (this.genieVersion !== '') {
      return new Promise<T[]>((resolve) => {
        (global as any).$4d?.WA_FetchData(
          tableName,
          sql,
          JSON.stringify(sanitisedSqlParameter),
          orderBy,
          limit,
          offset,
          function (resultJson: string) {
            resolve(JSON.parse(resultJson));
          }
        );
      });
    }

    const sqlString = getSqlString(sql, sanitisedSqlParameter);

    return new Promise<T[]>((resolve) => {
      (global as any).$4d?.WA_FetchData(tableName, sqlString, function (resultJson: string) {
        resolve(JSON.parse(resultJson));
      });
    });
  };

  close = async (success: boolean): Promise<void> => {
    return new Promise<void>((resolve) => {
      (global as any).$4d?.WA_Close(success, () => resolve());
    });
  };

  getCurrentGenieContext = async (): Promise<GenieContext> => {
    const genieContext = await new Promise<GenieContext>((resolve) => {
      (global as any).$4d?.WA_GetCurrentContext(function (resultJson: string) {
        resolve(JSON.parse(resultJson));
      });
    });
    if (!genieContext) {
      throw new Error('Current Context not found');
    }
    return genieContext;
  };

  getConstant = async (): Promise<GenieConstant> => {
    const idField: keyof GenieConstant = 'Id';
    const [record] = await this.fetchData<GenieConstant>(
      GenieTableName.Constant,
      `${idField} IS NOT NULL`
    );
    if (!record) {
      throw new Error('Constant not found');
    }
    return record;
  };

  getPatient = async (id: string | number): Promise<GeniePatient> => {
    const idField: keyof GeniePatient = 'Id';
    const [record] = await this.fetchData<GeniePatient>(GenieTableName.Patient, `${idField}=:id`, {
      id,
    });
    if (!record) {
      throw new Error('Patient not found');
    }
    return record;
  };

  getInterestedPartyByPatientId = async (
    patientId: string | number
  ): Promise<GenieInterestedParty[]> => {
    const idField: keyof GenieInterestedParty = 'PT_Id_Fk';
    const interestedParties = await this.fetchData<GenieInterestedParty>(
      GenieTableName.InterestedParty,
      `${idField}=:id`,
      {
        id: patientId,
      }
    );
    return interestedParties;
  };

  searchPractitionerAddressBook = async (
    query: string,
    count: number,
    offset: number
  ): Promise<GenieAddressBook[]> => {
    const addresses = await this.fetchData<GenieAddressBook>(
      GenieTableName.AddressBook,
      `FullName=:fullName | Suburb=:suburb`,
      {
        fullName: `@${query}@`,
        suburb: `@${query}@`,
      },
      'FullName',
      count,
      offset
    );
    return addresses;
  };

  getAddressBookData = async (addressBookId: string | number): Promise<GenieAddressBook[]> => {
    const idField: keyof GenieAddressBook = 'Id';
    const addressesData = await this.fetchData<GenieAddressBook>(
      GenieTableName.AddressBook,
      `${idField}=:addressBookId`,
      {
        addressBookId,
      }
    );
    return addressesData;
  };

  getPreference = async (username: string): Promise<GeniePreference> => {
    const usernameField: keyof GeniePreference = 'Username';
    const [record] = await this.fetchData<GeniePreference>(
      GenieTableName.Preference,
      `${usernameField}=:username`,
      {
        username,
      }
    );
    if (!record) {
      throw new Error('Preference record not found ');
    }
    return record;
  };

  getPracticePreference = async (id: string | number): Promise<GeniePracticePreference> => {
    const idField: keyof GeniePracticePreference = 'Id';
    const [record] = await this.fetchData<GeniePreference>(
      GenieTableName.PracticePreference,
      `${idField}=:id`,
      {
        id,
      }
    );
    if (!record) {
      throw new Error('PracticePreference record not found ');
    }
    return record;
  };

  listPreferences = async (): Promise<GeniePreference[]> => {
    const idField: keyof GeniePreference = 'Id';
    const records = await this.fetchData<GeniePreference>(
      GenieTableName.Preference,
      `${idField} IS NOT NULL AND Inactive = False`
    );
    return records;
  };

  listPatients = async (): Promise<GeniePatient[]> => {
    const idField: keyof GeniePatient = 'Id';
    const records = await this.fetchData<GeniePatient>(
      GenieTableName.Patient,
      `${idField} IS NOT NULL`
    );
    return records;
  };

  listPracticePreferences = async (): Promise<GeniePracticePreference[]> => {
    const idField: keyof GeniePracticePreference = 'Id';
    const records = await this.fetchData<GeniePracticePreference>(
      GenieTableName.PracticePreference,
      `${idField} IS NOT NULL`
    );
    return records;
  };

  listSitesByUsername = async (username: string): Promise<GenieSite[]> => {
    const usernameField: keyof GenieSite = 'PRFUSR_Username_Fk';
    const records = await this.fetchData<GenieSite>(
      GenieTableName.Site,
      `${usernameField}=:username`,
      {
        username,
      }
    );
    return records;
  };

  listSites = async (): Promise<GenieSite[]> => {
    const usernameField: keyof GenieSite = 'PRFUSR_Username_Fk';
    const records = await this.fetchData<GenieSite>(
      GenieTableName.Site,
      `${usernameField} IS NOT NULL`
    );
    return records;
  };

  getAttachmentFileById = async (id: number): Promise<string> => {
    const getAttachmentFile = await new Promise<string>((resolve) => {
      (global as any).$4d?.WA_GetAttachmentFile(id, function (resultJson: string) {
        resolve(resultJson);
      });
    });
    return Buffer.from(getAttachmentFile, 'base64').toString('binary');
  };

  isTrueFlag = async (featureFlag: string): Promise<boolean> => {
    const isTrueFlag = await new Promise<boolean>((resolve) => {
      (global as any).$4d?.SysLookup_IsTrueFlag(featureFlag, function (resultJson: string) {
        resolve(JSON.parse(resultJson));
      });
    });

    return isTrueFlag;
  };

  listAttachmentsByPatientId = async (id: string | number): Promise<GenieGraphic[]> => {
    const idField: keyof GenieGraphic = 'PT_Id_Fk';
    const records = await this.fetchData<GenieGraphic>(GenieTableName.Graphic, `${idField}=:id`, {
      id,
    });
    return records;
  };

  getCurrentProcedure = async (): Promise<ProcedureContext> => {
    return new Promise<ProcedureContext>((resolve) => {
      (global as any).$4d?.WA_GetCurrentProcedure(function (resultJson: string) {
        resolve(JSON.parse(resultJson));
      });
    });
  };

  getOperationsByProcedure = async (procedureId: string | number): Promise<GenieOperation[]> => {
    const idField: keyof Pick<GenieOperation, 'PRCDRE_Id_Fk'> = 'PRCDRE_Id_Fk';
    const operations = await this.fetchData<GenieOperation>(
      GenieTableName.Operation,
      `${idField}=:id`,
      {
        id: procedureId,
      }
    );
    return operations;
  };

  listHealthFunds = async (): Promise<GenieHealthFund[]> => {
    return this.fetchData<GenieHealthFund>(GenieTableName.HealthFund, 'Id!=0');
  };

  listHOParticipants = async (): Promise<GenieHOParticipant[]> => {
    return this.fetchData<GenieHOParticipant>(GenieTableName.HOParticipant, 'Id!=0');
  };

  getPathologyLabList = async (): Promise<GenieLaboratory[]> => {
    return this.fetchData<GenieLaboratory>(GenieTableName.Laboratory, 'Id!=0');
  };

  getRadiologyLabList = async (): Promise<GenieRadiologist[]> => {
    return this.fetchData<GenieRadiologist>(GenieTableName.Radiologist, 'Id!=0');
  };

  getCurrentPreganancy = async (patientId: string | number): Promise<GeniePregnancy[]> => {
    const idField: keyof Pick<GeniePregnancy, 'PT_Id_Fk'> = 'PT_Id_Fk';
    const preganancy = await this.fetchData<GeniePregnancy>(
      GenieTableName.Pregnancy,
      `${idField}=:id`,
      {
        id: patientId,
      }
    );

    return preganancy;
  };

  getBillingItems = async (itemNumbers: string[]): Promise<GenieBillingItem[]> => {
    return this.fetchData<GenieBillingItem>(GenieTableName.BillingItem, 'ItemNum IN :itemNumbers', {
      itemNumbers,
    });
  };
}
