import { datadogRum } from "@datadog/browser-rum";

import {
  datadogApplicationId,
  datadogClientToken,
  datadogEnabled,
  environment,
  version,
} from "./constants";

export const datadogRumInit = (service: string) => {
  
  if (datadogEnabled) {
    datadogRum.init({
      applicationId: datadogApplicationId || "",
      clientToken: datadogClientToken || "",
      site: "datadoghq.com",
      service,
      env: environment,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask",
      silentMultipleInit: true,
    });
    datadogRum.startSessionReplayRecording();
  }
};

export default datadogRumInit;
