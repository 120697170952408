import { FeatureFlagConfig } from "@genie-engineering/feature-flagging";

const featureFlagConfig: FeatureFlagConfig = {
  flag_card_mappings: {
    local: true,
    dev: true,
    staging: true,
    production: false,
  }
};

export default featureFlagConfig;
