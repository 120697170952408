import axios from "axios"
import { SDKContext } from "@genie-engineering/sdk-api"

export const devSignIn = async (): Promise<SDKContext["auth"]>=>{
  const signInUrl = "https://api.dev.genie-platform-test.com/pp-auth/practice/signin"
  const locationId = process.env.REACT_APP_DEV_LOCATION_ID
  const practiceId = process.env.REACT_APP_DEV_PRACTICE_ID
  const username = process.env.REACT_APP_DEV_COGNITO_USERNAME
  const password = process.env.REACT_APP_DEV_COGNITO_PASSWORD
  const { data: { cognitoIdToken, rolesToken } } = await axios({
    method: 'POST',
    url: signInUrl,
    data: { locationId, practiceId, username, password }
  })
  return{
    cognitoIdToken, rolesToken
  };
} 

type GetPlatformTokensResult = {
  cognitoIdToken: string;
  rolesToken: string;
};

export const signIn = async (): Promise<SDKContext["auth"]>=>{
  return new Promise((resolve, reject) => {
    (global as any).$4d.WA_GetPlatformTokens((resultJson: string) => {
      const tokenResult = JSON.parse(resultJson);

      if (tokenResult?.errorMessage) {
        console.log('token error', tokenResult.errorMessage);
        return reject(tokenResult.errorMessage);
      } 
      
      const tokens = tokenResult as GetPlatformTokensResult;

      return resolve({
        cognitoIdToken: tokens.cognitoIdToken,
        rolesToken: tokens.rolesToken,
      });
    });
  });
}

