import dataURItoBlob from './dataURItoBlob';
import printInFrame from './printInIframe';
import supportsAutoPrint from './supportsAutoPrint';

export const doPrint = async (url: string): Promise<void> => {
  let printUrl = url;
  if (url.startsWith('data:')) {
    const blob = dataURItoBlob(url);
    printUrl = URL.createObjectURL(blob);
  }
  if (supportsAutoPrint()) {
    const iframe = document.createElement('iframe');
    await printInFrame(iframe, printUrl);
  } else {
    throw new Error('Unexpected Error - print in IFrame not supported');
  }
};
