// Need to use buffer package for browser
import { Buffer } from 'buffer';

function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const buffer = Buffer.from(dataURI.split(',')[1], 'base64');

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the buffer to a blob, and you're done
  const blob = new Blob([buffer], { type: mimeString });
  return blob;
}

export default dataURItoBlob;
