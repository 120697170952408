import React, { useEffect, useState } from "react";
import { signIn, devSignIn } from './auth'
import { AppInfo } from './types'
import { GenieSDKOptions, createGenieSDK } from "./sdk";
import { SDKAdapter } from "@genie-engineering/sdk-api";
import LoadingOverlay from "./LoadingOverlay";
import "./app.css";
import { datadogRumInit } from "./app-analytics/datadog";
import { initFeatureFlags } from "@genie-engineering/feature-flagging";
import featureFlagConfig from "./features";
const signInFunc = process.env.REACT_APP_DEV_AUTH === 'true' ? devSignIn : signIn;

const urlParamGetter = (parameters: URLSearchParams, prefix: string) => (name: string) => parameters.get(`${prefix}${name}`) ?? undefined;

function getAppInfo(parameters: URLSearchParams): AppInfo {
  const getParameter = urlParamGetter(parameters, 'app_');

  const url = getParameter('url');

  if (!url) {
    throw new Error('app url is required');
  }

  return {
    url,
    view: getParameter('view'),
    menu: getParameter('menu'),
    name: getParameter('name'),
  };
}

function App() {
  datadogRumInit("genie-landing-page");
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // on page mounted
  useEffect(()=>{
    if (!(global as any).$4d) {
      return
    }

    initFeatureFlags(featureFlagConfig)

    const searchParams = new URLSearchParams(window.location.search);
    const appInfo = getAppInfo(searchParams);
    const contextParamGetter = urlParamGetter(searchParams, 'context_');
    const options: GenieSDKOptions = {
      view: appInfo.view || 'create',
      source: contextParamGetter('source'),
      date: contextParamGetter('date'),
    };

    if (appInfo.menu) {
      options.contextOptions = {
        menu: appInfo.menu.split(','),
      };
    }

    console.debug('landing page mounted', {appInfo});

    signInFunc().then(async (authTokens)=>{
      console.debug('sign in successfully');

      const sdk = await createGenieSDK(authTokens, options);
      SDKAdapter.registerSDK('genie', sdk, appInfo.url)
      console.debug('SDK registered', appInfo.url);
      setSrc(appInfo.url);
      console.debug('app url set', appInfo.url);
    }).catch((err) => {
      console.log('Error initializing sdk', err);
      setError(err);
    });

  }, []);

  if (!(global as any).$4d) {
    return <p>Not in Genie desktop application!</p>
  }

  if (error) {
    return <p>Error initializing sdk"</p>
  }

  return (
    <>
      {loading && <LoadingOverlay message="Loading..." />}
      <iframe
        src={src}
        style={{
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0px",
          border: "solid 0px #ccc",
          borderRadius: "4px",
          overflow: "hidden"
        }}
        title="Child iframe"
        onLoad={()=>{setLoading(false)}}
      >
      </iframe>
    </>
  );
}

export default App;
